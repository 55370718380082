import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import * as styles from '../../styles/TwoThirdsBackground.module.scss';
import { useSelector } from 'react-redux';
import { PrismicRichText } from '@prismicio/react';

const TwoThirdsBackground = ({ slice }) => {
    const isMobile = useSelector(state => state.isMobile);
    const isTablet = useSelector(state => state.isTablet);

    let backgroundStyles;
    if(isMobile || isTablet) {
        backgroundStyles = {
            background: "url(" + slice.primary.background_image.url + ") no-repeat center / cover",
        };
    } else {
        backgroundStyles = {
            background: "url(" + slice.primary.background_image_desktop.url + ") no-repeat center / cover",
        };
    }

    return (
        <div className={styles.TwoThirdsBackgroundWrap} style={backgroundStyles}>
            <div className={styles.CopyBlock}>
                <PrismicRichText field={slice.primary.copy.richText} />
            </div>
        </div>
    );
}

export const query = graphql`
    fragment SectionDataBodyTwoThirdsBackground on PrismicSectionDataBodyTwothirdsBackgroundImage {
        primary {
            background_image {
                url
              }
              background_image_desktop {
                url
              }
              copy {
                richText
              }
          }
    }
`;

export default TwoThirdsBackground;